<template>
  <div class="confirm">
    <el-form  ref="form" :model="form" label-width="100px">
        <el-form-item label="SN"></el-form-item>
    </el-form>
  </div>
</template>

<script>
import { requestForm } from '@/assets/js/http.js'
export default {
  name: 'SNDialog',
  data () {
    return {
      form: {}
    }
  },
  created () {
    var id = this.$route.query.id
    requestForm('/api/repair/repair/queryRepairSn?id=' + id, 'post').then((res) => {
      if (res.code === 200) {
      }
    })
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">

</style>
